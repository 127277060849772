import { render, staticRenderFns } from "./AddExperiment.vue?vue&type=template&id=95027ebe&scoped=true&"
import script from "./AddExperiment.vue?vue&type=script&lang=ts&"
export * from "./AddExperiment.vue?vue&type=script&lang=ts&"
import style0 from "./AddExperiment.vue?vue&type=style&index=0&id=95027ebe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95027ebe",
  null
  
)

export default component.exports