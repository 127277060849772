import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Experiment {
  @JsonProperty('id')
  private _id?: number | undefined;
  @JsonProperty('name')
  private _name: string;
  @JsonProperty('description')
  private _description: string;
  @JsonProperty('start')
  private _start: string;
  @JsonProperty('end')
  private _end: string;
  @JsonProperty('participants')
  private _participants: string[];
  @JsonProperty('tasks')
  private _tasks: string[];

  constructor(name: string, description: string, start: string, end: string, participants: string[], tasks: string[]) {
    this._name = name;
    this._description = description;
    this._start = start;
    this._end = end;
    this._participants = participants;
    this._tasks = tasks;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get start(): string {
    return this._start;
  }

  set start(value: string) {
    this._start = value;
  }

  get end(): string {
    return this._end;
  }

  set end(value: string) {
    this._end = value;
  }

  get participants(): string[] {
    return this._participants;
  }

  set participants(value: string[]) {
    this._participants = value;
  }

  get tasks(): string[] {
    return this._tasks;
  }

  set tasks(value: string[]) {
    this._tasks = value;
  }
}
