







































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';

@Component
export default class AddExperiment extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected experiment = new Experiment('', '', new Date().toLocaleString(), new Date().toLocaleString(), [], []);

  protected starttime = '';
  protected endtime = '';

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiment', to: '/experiment' },
        { text: 'Add', active: true },
      ];
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin' });
  }

  protected saveExperiment(): void {
    this.setLoading(true);
    ExperimentService.saveExperiment(this.experiment, this.starttime, this.endtime)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/experiment/admin' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add experiment. ' + backendError.response.data.message, 'danger');
        }
      });
  }
}
