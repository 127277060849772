import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import Experiment from '@/models/experiment/Experiment';
import { AxiosResponse } from 'axios';

export default class ExperimentService {
  private static mainURL = '/experiment';

  public static getAllExperimentsForUser(): Promise<Experiment[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, Experiment));
    });
  }

  public static getExperimentById(id: number): Promise<Experiment> {
    return MainService.get(this.mainURL + '/' + id).then((response) => {
      return deserialize(response.data, Experiment);
    });
  }

  public static saveExperiment(experiment: Experiment, starttime: string, endtime: string): Promise<Experiment> {
    experiment.start = experiment.start + 'T' + starttime + ':00Z';
    experiment.end = experiment.end + 'T' + endtime + ':00Z';
    return MainService.post(this.mainURL, JSON.stringify(serialize(experiment))).then((response) => {
      return deserialize(response.data, Experiment);
    });
  }

  public static deleteExperiment(id: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + id);
  }

  public static updateExperimentParticipants(experiment: Experiment): Promise<Experiment> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(experiment))).then((response) => {
      return deserialize(response.data, Experiment);
    });
  }

  public static importParticipants(file: File, experimentId: number) {
    const formData = new FormData();
    formData.append('file', file);
    return MainService.post(this.mainURL + '/participants/' + experimentId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      return response.data;
    });
  }
}
